import { PlainMessage } from "@bufbuild/protobuf";
import { Money } from "@egocentric-systems/ts-apis/types/v1/money_pb";
import { moneyToNumber } from "./utils";

const MINOR_DIGITS = 1e6;

type PlainMoney = PlainMessage<Money>;

export interface MathMoney extends PlainMoney {
  add: (summand?: PlainMoney) => MathMoney;
  subtract: (value?: PlainMoney) => MathMoney;
  scale: (n: number) => MathMoney;
  orMax: (max?: PlainMoney) => MathMoney;
  orMin: (min?: PlainMoney) => MathMoney;
  valueOf: () => number;
  truncateAfter: (digits: number) => MathMoney;
  plain: () => PlainMoney;
}

export interface ClientMoney extends PlainMoney {
  toJson?: never;
  add?: never;
}

function getCarryFromMinor(minorWithCarry: number) {
  if (minorWithCarry >= 0) {
    return {
      carry: Math.floor(minorWithCarry / MINOR_DIGITS),
      minor: minorWithCarry % MINOR_DIGITS,
    };
  } else {
    return {
      carry: 1 + Math.floor(Math.abs(minorWithCarry) / MINOR_DIGITS),
      minor: (minorWithCarry + MINOR_DIGITS) % MINOR_DIGITS,
    };
  }
}

function sumMoneys(
  m1: PlainMoney | MathMoney,
  m2?: PlainMoney | MathMoney,
): MathMoney {
  if (m2 && m1.currency !== m2.currency) {
    throw Error("Cant sum money with different currencies");
  }

  const minorWithCarry = m1.minor + (m2?.minor ?? 0);

  const { carry, minor } = getCarryFromMinor(minorWithCarry);

  return MathMoney({
    major: m1.major + (m2?.major ?? 0) + carry,
    minor,
    currency: m1.currency,
  });
}

function subtractMoneys(
  m1: PlainMoney | MathMoney,
  m2?: PlainMoney | MathMoney,
): MathMoney {
  if (m2 && m1.currency !== m2.currency) {
    throw Error("Cant subtract money with different currencies");
  }

  const minorWithCarry = m1.minor - (m2?.minor ?? 0);

  const { carry, minor } = getCarryFromMinor(minorWithCarry);

  return MathMoney({
    major: m1.major - (m2?.major ?? 0) - carry,
    minor,
    currency: m1.currency,
  });
}

function scaleMoney(m: PlainMoney, n: number): MathMoney {
  const minorWithCarry = n * m.minor;

  const { carry, minor } = getCarryFromMinor(minorWithCarry);

  const major = n * m.major + carry;

  return MathMoney({
    major: Math.floor(major),
    minor: Math.round(minor + (major % 1) * MINOR_DIGITS),
    currency: m.currency,
  });
}

function orMax(money: PlainMoney, max?: PlainMoney) {
  if (!max) {
    return MathMoney(money);
  }

  if (money.currency !== max.currency) {
    throw Error("Cant compare money with different currencies");
  }

  return moneyToNumber(money) > moneyToNumber(max)
    ? MathMoney(max)
    : MathMoney(money);
}

function orMin(money: PlainMoney, min?: PlainMoney) {
  if (!min) {
    return MathMoney(money);
  }

  if (money.currency !== min.currency) {
    throw Error("Cant compare money with different currencies");
  }

  return moneyToNumber(money) < moneyToNumber(min)
    ? MathMoney(min)
    : MathMoney(money);
}

function truncateAfter(money: PlainMoney, digits: number): MathMoney {
  const factor = 10 ** (6 - digits);

  const minor = money.minor ?? 0;

  const truncatedMinor = Math.floor(minor / factor) * factor;

  return MathMoney({
    major: money.major ?? 0,
    minor: truncatedMinor,
    currency: money.currency,
  });
}

export function MathMoney({
  major = 0,
  minor = 0,
  currency,
}: Partial<PlainMoney> & Pick<PlainMoney, "currency">): MathMoney {
  const m = { major, minor, currency };

  return {
    ...m,
    add: (summand) => sumMoneys(m, summand),
    subtract: (value) => subtractMoneys(m, value),
    scale: (n) => scaleMoney(m, n),
    orMax: (max) => orMax(m, max),
    orMin: (min) => orMin(m, min),
    valueOf: () => moneyToNumber(m),
    truncateAfter: (digits) => truncateAfter(m, digits),
    plain: () => m,
  };
}
